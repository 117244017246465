import {$KTM, KeyMetadatas, TypedObject} from "../shared/types";
import {SvgIcon} from "@mui/material";
import {DesktopWindowsOutlined, SettingsOutlined, SpaceDashboardOutlined} from "@mui/icons-material";
import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../shared/json/json-property";
import {getMemberAuth} from "../shared/auth";
import {Type} from "../shared/json/helpers";
import {FormGen} from "../shared/formgen";

export enum Tab {
  DASHBOARD = "dashboard",
  WORKBENCH = "workbench",
  SETTINGS = "settings",
}

export type TabMetadata = {
  title: string,
  iconType: typeof SvgIcon,
}

export const TABS_KTM = new KeyMetadatas<TabMetadata>([
  $KTM<TabMetadata>(Tab.DASHBOARD, {
    title: "Dashboard",
    iconType: SpaceDashboardOutlined,
  }),
  $KTM<TabMetadata>(Tab.WORKBENCH, {
    title: "Workbench",
    iconType: DesktopWindowsOutlined,
  }),
  $KTM<TabMetadata>(Tab.SETTINGS, {
    title: "Settings",
    iconType: SettingsOutlined,
  }),
]);

@JsonObject()
export class Settings extends TypedObject {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = await Settings.load();
  }

  private readonly memberAuth = getMemberAuth();

  readonly id: string = "settings";

  @FormGen({
    name: "Feedback",
    description: "Allow users to send feedback on model responses to Fyne AI",
    type: "boolean"
  })
  @JsonProperty()
  feedback: boolean;

  constructor() {
    super(null, null);
  }

  static async load() {
    const obj = new Settings();
    if (!obj.memberAuth) {
      return null;
    }
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return Settings;
  }
}