import {PathComponent} from "index";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../shared/PageFragment";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../shared/StyledComponents";
import {ButtonBase, Card, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Settings, Tab, TABS_KTM} from "./types";
import {DIVIDER, PD_MD, PD_XLG, SZ_SM} from "../shared/dimens";
import {App, COLOR_PRIMARY} from "./App";
import {UserCache, UserProfilePhoto} from "../shared/types";
import {UserFragment} from "../shared/UserFragment";
import {getAuth} from "@firebase/auth";
import {User} from "../shared/entities";
import {FormGenContainer} from "../shared/FormGenContainer";
import {PlaceholderFragment} from "../shared/PlaceholderFragment";
import {DashboardFragment} from "./dashboard/DashboardFragment";
import {WorkbenchFragment} from "./workbench/WorkbenchFragment";

export type MainProps = PageFragmentProps & {}

type MainState = PageFragmentState & {
  user: User,
  selectedTab: Tab,
}

function TabView(props: { tabKey: string, selected?: boolean }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const metadata = TABS_KTM.findMetadata(props.tabKey);
  return <>
    {md
      ? <Typography variant="h6">{metadata.title}</Typography>
      : <metadata.iconType style={{width: 32, height: 32}}/>}
    {props.selected
      ?
      <div style={{position: "absolute", width: "100%", height: 4, bottom: 0, background: COLOR_PRIMARY}}/>
      : null}
  </>;
}

function Logo(): ReactElement {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <img src={md ? "/stamp.png" : "/logo192.png"} style={{height: 40, alignSelf: "center"}}/>;
}

export class Main extends PageFragment<MainProps, MainState> {

  private readonly auth = getAuth();

  protected onCreateState(): MainState {
    return {
      ...super.onCreateState(),
      selectedTab: Tab.DASHBOARD,
    };
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const user = await UserCache.getInstance().getUser(this.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  renderContent(): React.ReactElement {
    return <>
      <StyledBoxRow style={{
        boxSizing: "border-box",
        flexShrink: 0,
        height: 72,
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        gap: PD_XLG,
        borderBottom: DIVIDER,
      }}>
        <Logo/>
        {TABS_KTM.values.map(value => <ButtonBase style={{position: "relative"}}
                                                  onClick={() => this.setState({selectedTab: value.key as Tab})}>
          <TabView tabKey={value.key} selected={value.key === this.state.selectedTab}/>
        </ButtonBase>)}
        <StyledSpan/>
        <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0, alignSelf: "center"}}>
          <ButtonBase
            onClick={() => App.CONTEXT.showDialog(
              null,
              () => <UserFragment uid={this.auth.currentUser.uid}/>)}
          >
            <img src={UserProfilePhoto(this.state.user)} style={{width: "100%", height: "100%"}}/>
          </ButtonBase>
        </Card>
      </StyledBoxRow>
      <StyledBoxColumn style={{flexGrow: 1, overflowY: "scroll"}}>
        {this.renderTabContent()}
      </StyledBoxColumn>
    </>
  }

  private renderTabContent() {
    switch (this.state.selectedTab) {
      default:
      case Tab.DASHBOARD:
        return this.renderTabContentDashboard();
      case Tab.WORKBENCH:
        return this.renderTabContentWorkbench();
      case Tab.SETTINGS:
        return this.renderTabContentSettings();
    }
  }

  private renderTabContentDashboard() {
    return <DashboardFragment path={this.props.path} />;
  }

  private renderTabContentWorkbench() {
    return <WorkbenchFragment path={this.props.path} />;
  }

  private renderTabContentSettings() {
    const settings = Settings.getInstance();
    return <StyledContainer>
      <FormGenContainer content={settings} autoSave onContainerSave={() => settings.save()}/>
    </StyledContainer>;
  }
}
