import {PageFragment} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledContainer} from "../../shared/StyledComponents";
import {Button, Typography} from "@mui/material";
import {PD_LG, PD_XLG} from "../../shared/dimens";
import {KeyOutlined, TextFormatOutlined} from "@mui/icons-material";
import {Action, UserDisplayName} from "../../shared/types";
import {getMemberAuth} from "../../shared/auth";

export class DashboardFragment extends PageFragment {

  private readonly memberAuth = getMemberAuth();

  private renderButton(action: Action): ReactElement {
    return <Button
      startIcon={<action.iconType style={{fontSize: "200%"}}/>}
      variant="outlined"
      onClick={action.onClick}
      style={{
        justifyContent: "flex-start",
        paddingLeft: PD_XLG,
        paddingRight: PD_XLG,
        paddingTop: PD_LG,
        paddingBottom: PD_LG,
        fontSize: "120%",
      }}>
      {action.text}
    </Button>;
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size="sm" style={{padding: PD_XLG, gap: PD_XLG}}>
      <StyledBoxColumn>
        <Typography variant="h4">Hello, {UserDisplayName(this.memberAuth.member.user)}</Typography>
        <Typography>What would you like to do today?</Typography>
      </StyledBoxColumn>
      {this.renderButton(new Action("Write a prompt", () => {}, TextFormatOutlined))}
      {this.renderButton(new Action("Get API keys", () => {}, KeyOutlined))}
    </StyledContainer>
  }
}