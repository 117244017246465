import {PageFragment} from "../../shared/PageFragment";
import React from "react";
import {PageWithSidebarContainer} from "../../shared/PageWithSidebarContainer";
import {SidebarTab} from "../../shared/Sidebar";
import {PlaceholderFragment} from "../../shared/PlaceholderFragment";
import {PathComponent} from "../../index";

const SIDEBAR_TABS: SidebarTab[] = [
  {
    id: "default",
    items: {
      groups: [
        {
          title: "Registry",
          items: [
            {
              path: "agents",
              title: "Agents",
              render: pathProps => <PlaceholderFragment/>,
            },
            {
              path: "datasets",
              title: "Datasets",
              render: pathProps => <PlaceholderFragment/>,
            },
          ],
        },
      ],
      variant: "condensed",
      containerId: "main",
    },
  }
]

export class WorkbenchFragment extends PageFragment {

  static nestedPaths(): PathComponent[] {
    return PageWithSidebarContainer.nestedPathsFromSidebarItems(SIDEBAR_TABS[0].items);
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer sidebarTabs={SIDEBAR_TABS}/>;
  }
}